/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://dtbe3rfudvbstahcmyddvwrvmu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-eagupwxqwfbq7ajwqksxjkqrvq",
    "aws_cognito_identity_pool_id": "us-east-1:e702daf3-210b-4092-8240-f2f015a7a431",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_PEUMhC7WW",
    "aws_user_pools_web_client_id": "5e5vf3gef66vmigmuphcvinep6",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "heroquest2ecaff30565146e3a46f821bb493582d123841-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_content_delivery_bucket": "heroquest-20230107101834-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d21ht3uiigmbpt.cloudfront.net"
};


export default awsmobile;
